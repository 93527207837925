// @import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "./assets/scss/_variables.scss";
@import "./assets/css/iqwizard.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body,
html {
  background-color: #ffffff !important;
}

// .main-container {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }

// .main {
//   height: calc(100vh - 120px);
//   overflow: hidden; /
//   flex-direction: row;
//   width: 100%;
// }

// .k-sidebar {
//   height: calc(100vh - 120px);
//   width: 320px;
// }

// .section {
//   flex: 1;
//   overflow-y: auto;
//   overflow-x: hidden;
//   height: 100%;
// }

// .scroll_section_wrap {
//   flex: 1;
//   height: 100%;
//   overflow-y: auto;
//   overflow-x: hidden;
// }

// .scroll_section {
//   height: 100%;
// }
.main-container,
.section,
.main {
  height: 100%;
}
// k-header
k-header {
  .k-main-header {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .k-header-title {
    max-width: 250px;
    margin: 10px 0;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    img {
      width: 100%;
      max-width: 190px;
      max-height: 60px;
    }
  }
}
k-header-right-section {
  .user-avatar {
    width: 34px !important;
    height: 34px !important;
    border-radius: 50%;
  }
}
kendo-popup.rightSide_list {
  left: auto !important;
  top: 47px !important;
  right: 7px !important;
  min-width: 226px;
  .dropdown-item:active {
    background: $default_brand;
  }
  .k-popup {
    border-radius: 3px;
  }
}
.side-nav-btn {
  margin: 17px 15px 11px 15px;
  padding: 0px;
  width: 20px;
  fa-icon {
    color: #fff;
  }
}
// k-header END

.page_title {
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  // background: #e7e7e9;
  background: #d9d9d9;
  h3 {
    margin: 0px;
    font-size: 19px;
    line-height: 34px;
    font-weight: 500;
    color: #101820;
    text-transform: uppercase;
    font-family: Merriweather;
  }
}
.page_space {
  padding: 15px 0 60px 0;
  // height: calc(100% - 75px);
  height: 100%;
}
.no_data_msg {
  padding: 25px;
  text-align: center;
}

// -----------------------------------------------------------------------------
//  Forms
// -----------------------------------------------------------------------------
input.form-control,
select.form-control,
select.custom-select,
textarea.form-control {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  background-color: #fff;
  // background-color: #fbf9f9;
  border-radius: 0;
  // &:read-only {
  //   border-color: rgba(206, 206, 206, 0.6);
  // }
}
.input-group-prepend,
.input-group-append {
  .input-group-text {
    border-radius: 0px;
  }
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

.assignUseCase_modal {
  .form-check-inline .form-check-input {
    margin-top: -4px;
  }
}

.form-group ::-webkit-input-placeholder {
  color: #aaa !important;
  font-size: 15px;
}

.form-group :-moz-placeholder {
  color: #aaa !important;
  opacity: 1 !important;
  font-size: 15px;
}

.form-group::-moz-placeholder {
  color: #aaa !important;
  opacity: 1 !important;
  font-size: 15px;
}

.form-group :-ms-input-placeholder {
  color: #aaa !important;
  font-size: 15px;
}

.form-group ::-ms-input-placeholder {
  color: #aaa !important;
  font-size: 15px;
}

.form-group ::placeholder {
  color: #aaa !important;
  font-size: 15px;
}

// -----------------------------------------------------------------------------
//  ngb-modal-window
// -----------------------------------------------------------------------------
ngb-modal-window {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background: $default_brand;
        border-radius: 0px;
        h4.iQmodal_title {
          color: #fff;
          font-size: 21px;
        }
        .close {
          margin: 3px -7px 0px 0px;
          color: #fff;
        }
      }
      .modal-footer {
        height: auto;
        padding: 12px 15px;
        .button_holder {
          k-button {
            button + button {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
// -----------------------------------------------------------------------------
//  ngx-modal
// -----------------------------------------------------------------------------
.ngx-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background: $default_brand;
        border-radius: 0px;
        h4.modal-title {
          color: #fff;
          font-size: 21px;
        }
      }
      .modal-footer {
        height: auto;
        padding: 12px 15px;
      }
    }
  }
}

// -----------------------------------------------------------------------------
//  smart Claim Modal
// -----------------------------------------------------------------------------
.timeout_modal {
  .modal-header {
    .modal-title {
      color: #fff;
    }
  }
}

// -----------------------------------------------------------------------------
//  smart Claim Modal
// -----------------------------------------------------------------------------
.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 1px;
    }
  }
  .modal-dialog-centered.modal-sm {
    max-width: 600px !important;
  }
  .modal-dialog-centered.modal-lg {
    max-width: 800px !important;
  }

  .assignUseCase_modal.modal-dialog-centered.modal-lg {
    max-width: 800px !important;
  }
}
.modal-backdrop {
  z-index: 1040 !important;
}
.smartClaimModal {
  .modal-header {
    background: $default_brand;
    border-radius: 0px;
    h5.modal-title {
      color: #fff;
    }
    h4.modal-title {
      color: #fff;
    }
    .close {
      position: inherit;
      top: auto;
      right: auto;
      color: #fff;
      margin: -1rem -1rem -1rem auto !important;
    }
  }
  .modal-body {
    padding: 15px;
    .form-group:last-child {
      margin-bottom: 0px;
    }
    .form-group {
      label {
        margin-bottom: 3px;
      }
    }
  }
  .modal-footer {
    padding: 0px 15px 15px 15px;
    height: auto;
    .button_holder {
      margin: 0px;
      .kcg-btn {
        margin: 0px;
      }
      k-button + k-button {
        margin-left: 15px;
      }
    }
  }
}

// -----------------------------------------------------------------------------
//  kendo modal
// -----------------------------------------------------------------------------
kendo-dialog {
  .k-window {
    @include border-radius(1px);
    .k-window-titlebar {
      @include border-radius(0px);
      background: $default_brand;
      .k-window-title {
        font-family: Merriweather;
      }
    }
    .kendoBtnHolder {
      text-align: right;
      button + button {
        margin-left: 15px;
      }
    }
  }
}
// -----------------------------------------------------------------------------
//  Error message
// -----------------------------------------------------------------------------

.help-block {
  color: red;
  font-size: 13px;
  font-weight: normal;
  margin-top: 4px;
}
.alertInfo {
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
}

// -----------------------------------------------------------------------------
//  iqwizard
// -----------------------------------------------------------------------------

h5.qstn_info {
  font-family: Kennedys !important;
  font-size: 18px;
}
.iqwizard_container {
  .iQswiper {
    .custom-control-label {
      padding-top: 0px;
    }
    .form-check.text-start {
      text-align: left;
      .form-check-input {
        margin-top: 6px;
      }
    }
    .visually-hidden,
    .visually-hidden-focusable:not(:focus):not(:focus-within) {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
}

// -----------------------------------------------------------------------------
//  Tab
// -----------------------------------------------------------------------------

.nav {
  border: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  li.nav-item {
    width: 18%;
    text-align: center;
    list-style: none;
  }

  a.nav-link {
    padding: 3px 15px;
  }
  a.nav-link,
  a.nav-link:active {
    color: #767b7f;
    border-bottom-width: 2px;
    border-style: solid;
    font-size: 19px;
    line-height: 20px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: #9e9e9e;
  }

  a.nav-link.active,
  a.nav-link.active:hover {
    color: $default_brand;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: $default_brand;
    //background: #ececec;
  }

  a.nav-link:hover {
    color: $default_brand;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: #9e9e9e;
  }
  a.nav-link.disabled {
    cursor: not-allowed;
  }
}

// -----------------------------------------------------------------------------
//  Custom btn fancy
// -----------------------------------------------------------------------------
.button_holder_left button + button {
  margin-left: 10px;
}

.btn_fancy {
  padding: 4px 25px;
  border-width: 2px;
  border-style: solid;
  // border-color: $default_brand;
  // background: $default_brand;
  // color: #fff;
  border-color: #ced4da;
  background: #fff;
  color: $iQ_color_dark_grey;
  overflow: hidden;

  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;

  border-radius: 0px;
  //box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: $default_brand;
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover {
    opacity: 0.7;
    color: #fff;
    cursor: not-allowed;
  }

  &:disabled:hover:after,
  &[disabled]:hover:after {
    opacity: 0.7;
    left: 0;
    width: 0;
    color: #fff;
  }
  &_round {
    border-radius: 25px;
    &:after {
      border-radius: 25px;
    }
  }
  &_brand {
    @extend .btn_fancy;
    border-color: $default_brand;
    background: $default_brand;
    color: #fff;
    &:after {
      background: #fff;
    }
    &:hover {
      color: $default_brand;
    }
  }
  &_brand_gray {
    @extend .btn_fancy;
    border-color: $iQ_color_highlight_grey;
    background: $iQ_color_highlight_grey;
    color: #fff;
    &:after {
      background: #fff;
    }
    &:hover {
      color: $iQ_color_highlight_grey;
    }
  }
  &_sm {
    @extend .btn_fancy;
    padding: 1px 15px;
    font-size: 14px;
  }
}
.btn_fancy.active {
  color: #fff;
  border-color: $default_brand;
  &:after {
    background: $default_brand;
    color: #fff;
    left: 0;
    width: 100%;
  }
}

.btn_fancy_roundEnds {
  padding: 4px 25px;
  border-width: 2px;
  border-style: solid;

  border-color: #ced4da;
  background: #fff;
  color: $iQ_color_dark_grey;
  overflow: hidden;

  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;

  border-radius: 24px;
  z-index: 1;
  &:hover {
    border-color: $default_brand;
    background: $default_brand;
    color: #fff;
    transition: all 0.3s ease;
  }
  &.active {
    border-color: $default_brand;
    background: $default_brand;
    color: #fff;
    transition: all 0.3s ease;
  }
}

// -----------------------------------------------------------------------------
//  Button icon
// -----------------------------------------------------------------------------

.buttom_svg_icon {
  padding: 0px;
  border: none;
  background: none;
}
// -----------------------------------------------------------------------------
//  Button icon
// -----------------------------------------------------------------------------

.add-new-btn {
  border: none;
  background: none;
  padding: 0;
  opacity: 0.7;
  margin-bottom: 0;
  cursor: pointer;
  line-height: 17px;
  i.fa {
    font-size: 24px;
  }
}

.add-new-btn:focus,
.add-new-btn:hover {
  border: none;
  background: none;
  opacity: 0.9;
}

.add-new-btn.disabled,
.add-new-btn.disabled:focus,
.add-new-btn.disabled:hover,
.add-new-btn[disabled],
.add-new-btn[disabled]:focus,
.add-new-btn[disabled]:hover {
  background: none;
  opacity: 0.5;
  cursor: not-allowed;
}

// -----------------------------------------------------------------------------
//  export Button
// -----------------------------------------------------------------------------
.exportBtn {
}
// -----------------------------------------------------------------------------
//  Custom Button
// -----------------------------------------------------------------------------

.button_holder button + button {
  margin-left: 10px;
}

.custom-button {
  position: relative;
  display: inline-block;
  padding: 10px 60px 10px 15px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;

  span {
    position: relative;
  }

  i {
    position: absolute;
    right: 15px;
    top: 27%;
    bottom: 0;
    z-index: 1;
    font-size: 22px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: " ";
    position: absolute;
    height: 100%;
    width: 1.5px;
    top: 0;
    right: 40px;
    -webkit-transform: skew(-19deg);
    -ms-transform: skew(-19deg);
    transform: skew(-19deg);
    transition: all 0.2s linear;
    display: block;
  }

  .loadingIcon {
    position: absolute;
    right: 14px;
    top: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.custom-button_sm {
  padding: 6px 60px 5px 15px;

  i {
    top: 35%;
    font-size: 14px;
  }
  .loadingIcon {
    top: 10px;
  }
}
.custom-button_xm {
  padding: 4px 52px 4px 10px;
  font-size: 0.875rem;

  i {
    top: 28%;
    font-size: 12px;
  }
  .loadingIcon {
    top: 10px;
  }
}

// Custom fill Button

.fill_button {
  border: 1px solid $default_brand;
  background: $default_brand;
  color: #fff;

  i {
    color: #fff;
  }

  &:hover {
    color: $default_brand;
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 40px;
    width: 0;
    height: 100%;
    background-color: #fff;
    transform-origin: left;
    transition: width 0.25s ease;
    transform: skew(-19deg);
  }

  &:hover::before {
    width: 100%;
    left: -40px;
  }

  &::after {
    background-color: #fff;
  }

  &:disabled {
    color: #fff;

    &:hover::before {
      width: 0;
    }
  }
}

// Custom strock Button

.strock_button {
  border: 1px solid $default_brand;
  background: none;
  color: $default_brand;

  i {
    color: $default_brand;
    z-index: 1;
  }

  &:hover {
    color: $default_brand;
    z-index: 1;

    i {
      color: #fff;
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: -10px;
    width: 0;
    height: 100%;
    background-color: $default_brand;
    transform-origin: left;
    transition: width 0.25s ease;
    transform: skew(-19deg);
  }

  &:hover::before {
    width: 50px;
    left: calc(100% - 40px);
  }

  &::after {
    background-color: $default_brand;
  }

  &:disabled {
    background: none;
    color: $default_brand;
    i {
      color: $default_brand;
    }

    &:hover::before {
      width: 0;
    }
  }
}
// Custom fill Button --  cancel

.custom-button_cancel.fill_button {
  border: 1px solid $iQ_color_dark_grey;
  background: $iQ_color_dark_grey;

  &:hover {
    color: $iQ_color_dark_grey;
  }
}

// Custom strock Button --  cancel

.custom-button_cancel.strock_button {
  border: 1px solid $iQ_color_dark_grey;
  color: $iQ_color_dark_grey;

  i {
    color: $iQ_color_dark_grey;
  }

  &:hover {
    color: $iQ_color_dark_grey;

    i {
      color: #fff;
    }
  }

  &::before {
    background-color: $iQ_color_dark_grey;
  }

  &::after {
    background-color: $iQ_color_dark_grey;
  }
}
.btn.resetBtn {
  background: $default_brand;
  i {
    color: #fff;
  }
  &:hover {
    background: rgba($default_brand, 0.7);
  }

  &:active,
  &:focus {
    background: rgba($default_brand, 9) !important;
  }
}

button:focus {
  outline: none;
  box-shadow: none;
}

// -----------------------------------------------------------------------------
//  sprite images
// -----------------------------------------------------------------------------

.action-icon-holder {
  display: table;
  margin: 0 auto;
}

.action-icon {
  float: left;
  width: 24px;
  height: 23px;
  margin: 0 5px;
  display: inline-block;
  background-image: url("assets/images/sprite_images.png");
  background-repeat: no-repeat;
  cursor: pointer;
}
.action-icon-btn {
  background-color: transparent;
  border: none;
}
button.action-icon-btn:disabled,
button.action-icon-btn[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}
.icon-edit {
  background-position: -97px -13px;
}
.icon-delete {
  background-position: -140px -13px;
}
.icon-organisation {
  background-position: -887px -62px;
}
// -----------------------------------------------------------------------------
//  backToPage
// -----------------------------------------------------------------------------
.backToPage {
  cursor: pointer;
  margin-right: 10px;
  font-size: 21px;
}
// -----------------------------------------------------------------------------
//  iQcard
// -----------------------------------------------------------------------------
.iQcard {
  margin: 0 0 15px 0;
  // box-shadow: 2px 2px 5px #d3d3d3;
  border-radius: 0;
  background: #fff;
  cursor: default;
  &_header {
    background: #fff;
    border: none;
    padding: 15px 15px 10px 15px;
  }
  &_body {
    padding: 10px 15px 15px 15px;
  }
  &_field {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .brand_icon.brand_icon_border {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 28px;
      height: 28px;
      font-size: 14px;
      background-color: #ffffff;
      border: 1.5px solid $iQ_color_dark_grey;
      color: $iQ_color_dark_grey;
      border-radius: 50%;
      img {
        width: 14px;
      }
    }
  }
  &_field:last-child {
    margin-bottom: 0px;
  }
  &_field-text {
    color: $iQ_color_dark_grey;
    font-size: 15px;
    margin-left: 10px;
    margin-bottom: 0px;
    width: calc(100% - 40px);
    align-items: center;
  }
}

.iQcard.item-hover-effect {
  -webkit-transition: all 200ms ease-in-out;
  -webkit-transform: scale(1);
  -ms-transition: all 200ms ease-in-out;
  -ms-transform: scale(1);
  -moz-transition: all 200ms ease-in-out;
  -moz-transform: scale(1);
  transition: all 200ms ease-in-out;
  transform: scale(1);

  -webkit-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.37);

  &:hover {
    -webkit-transition: all 200ms ease-in-out;
    -webkit-transform: scale(1.02);
    -ms-transition: all 200ms ease-in-out;
    -ms-transform: scale(1.02);
    -moz-transition: all 200ms ease-in-out;
    -moz-transform: scale(1.02);
    transition: all 200ms ease-in-out;
    transform: scale(1.02);

    text-decoration: none;
    -webkit-box-shadow: 5px 5px 15px -3px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 5px 5px 15px -3px rgba(0, 0, 0, 0.61);
    box-shadow: 5px 5px 15px -3px rgba(0, 0, 0, 0.61);
  }

  &[hidden] {
    display: none !important;
  }
}

.time_date_info {
  background-color: $default_brand;
  padding: 4px 10px;
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  .brand_icon {
    // display: inline-block;
    // margin-right: 2px;
    margin-top: -1px;
    // vertical-align: top;
    float: left;
    margin-right: 5px;
  }
  svg {
    fill: #ffffff !important;
  }
}
ul.card_more_dropdown {
  padding: 0;
  margin: 0;

  li {
    float: left;
    list-style: none;
    position: relative;
    cursor: pointer;
    ul {
      width: 170px;
      position: absolute;
      right: -11px;
      top: 22px;
      display: none;
      padding: 0 0 1px;
      margin: 0;
      background: #fff;
      border: 1px solid #cccc;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.175);
      -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.175);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.175);
      z-index: 1000;

      li {
        float: inherit;
        display: block;
        padding: 5px 12px;
        font-size: 13px;
        line-height: 26px;
        text-decoration: none;
        color: #393939;
        border-bottom: 1px solid #e6e8ea;
        transition: all 0.1s ease-out;
        cursor: pointer;
        &:first-of-type {
          border-radius: 4px 4px 0 0;
        }
        &:last-of-type {
          border-bottom: none;
        }
        &:last-of-type a {
          border-radius: 0 0 4px 4px;
          border: none;
        }
        &:hover {
          background: $default_brand;
          color: #fff;
          svg {
            fill: #fff;
          }
        }
      }
    }

    ul:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 15px;
      border-width: 0 6px 6px 6px;
      border-style: solid;
      border-color: #fff transparent;
    }

    ul:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 13px;
      border-width: 0 8px 8px 8px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.1) transparent;
    }
  }

  li:hover ul {
    display: inline-block;
  }
}

.iQcard.iQcardOverlay {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #808080a3;
    top: 0px;
    left: 0px;
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.iQcard.iQcardOverlay.item-hover-effect:hover {
  -webkit-transform: scale(1);

  -ms-transform: scale(1);

  -moz-transform: scale(1);

  transform: scale(1);
}
.iQcard.horizontal_iQcard {
  padding: 0px;

  .iQcard_body {
    padding: 10px 15px;
  }
  .horizontal_row {
    align-items: center;
  }
}

// -----------------------------------------------------------------------------
//  SVG
// -----------------------------------------------------------------------------

.brand_icon_ring.brand_icon_border {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 28px;
  height: 28px;
  font-size: 14px;
  background-color: #ffffff;
  border: 1.5px solid $iQ_color_dark_grey;
  color: $iQ_color_dark_grey;
  border-radius: 50%;
}

.brand_icon {
  svg {
    width: 100%;
    height: 100%;
    fill: $iQ_color_dark_grey;
  }
}
.brand_icon.assigned {
  svg {
    width: 100%;
    height: 100%;
    fill: $default_brand;
  }
}

.brand_icon_12 {
  width: 12px;
  height: 12px;
}
.brand_icon_14 {
  width: 14px;
  height: 14px;
}
.brand_icon_19 {
  width: 19px;
  height: 19px;
}
.brand_icon_28 {
  width: 28px;
  height: 28px;
}
.brand_icon_32 {
  width: 32px;
  height: 32px;
}
.brand_icon_34 {
  width: 34px;
  height: 34px;
}
.brand_icon_42 {
  width: 42px;
  height: 42px;
}
.svgBtn {
  cursor: pointer;
  display: inline-block;
  &:hover svg {
    -webkit-transition: all 200ms ease-in-out;
    -webkit-transform: scale(1.2);
    -ms-transition: all 200ms ease-in-out;
    -ms-transform: scale(1.2);
    -moz-transition: all 200ms ease-in-out;
    -moz-transform: scale(1.2);
    transition: all 200ms ease-in-out;
    transform: scale(1.2);
  }
}

// -----------------------------------------------------------------------------
//  iQtable
// -----------------------------------------------------------------------------
.iQtable {
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: #dee2e6;
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
}
.iQtable > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
}
.iQtable > tbody > tr:hover > * {
  background: rgba(0, 0, 0, 0.075);
}
// -----------------------------------------------------------------------------
//  chip
// -----------------------------------------------------------------------------
.iQ_chip {
  padding: 0px 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #ced4da;
  background: #fff;
  color: $iQ_color_dark_grey;
  font-weight: 500;
  background: transparent;
  //cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  text-align: center;
  @include border-radius(25px);
}
// -----------------------------------------------------------------------------
//  table
// -----------------------------------------------------------------------------
.grid_top_button {
  border: 1px solid #ccc;
  padding: 5px;
}

.tableWrap {
  overflow-y: auto;
}

table.table {
  table-layout: fixed;
  min-width: 730px;
  margin: 0;

  .icon-disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  td,
  th {
    border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    -ms-word-break: break-all;
    font-size: 15px;
  }

  thead {
    tr {
      th {
        background: $default_brand;
        // background: #e7e7e9;
        border-top-color: $default_brand;
        color: #fff;
        padding: 8px 10px;
        text-align: left;
        font-weight: normal;
        border-bottom: none;
      }

      th:first-child {
        border-left: 1px solid $default_brand;
      }

      th:last-child {
        border-right: 1px solid $default_brand;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: #fbfbfb;
    }

    tr:nth-child(even) {
      background: rgb(255, 255, 255);
    }

    tr {
      &:hover {
        background: #dededf;
      }

      td {
        padding: 6px 10px;
        line-height: 23px;
        vertical-align: top;
        text-align: left;
      }
    }
  }

  tfoot {
    background: #e7e7e9;

    tr {
      th {
        padding: 4px;
      }

      td {
        padding: 4px;
        vertical-align: middle;
      }
    }
    .grid_pagination {
      margin: 0;
      float: right;

      .pagination {
        margin: 0;
      }

      .page-item.active .page-link {
        background-color: $default_brand;
        border-color: $default_brand;
        color: #fff;
      }

      .page-item .page-link {
        font-size: 11px;
        line-height: 15px;
        z-index: 0;
        color: $default_brand;
      }

      .page-item.disabled .page-link {
        border: 1px solid #ced4da;
        color: #6c757d;
      }
    }
  }
}

// -----------------------------------------------------------------------------
//  legend
// -----------------------------------------------------------------------------
.iQ_legend_container {
  text-align: center;
  border: none !important;
}
.iQ_legend_ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  li {
    // float: left;
    display: inline-block;
    margin: 10px 12px;
    line-height: 12px;

    span.iQ_legend_color {
      // background: rgba(255, 99, 132, 0.5);
      // border-color: rgb(255, 99, 132);
      float: left;
      height: 12px;
      margin-right: 10px;
      width: 26px;
    }
    p {
      margin: 0px;
      padding: 0px;
    }
  }
}

// -----------------------------------------------------------------------------
//  Animation
// -----------------------------------------------------------------------------

.iQanimated {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.iQanimated.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}

// Animation content end

// -----------------------------------------------------------------------------
//  mat-datepicker
// -----------------------------------------------------------------------------
.mat-form-field-underline {
  display: none;
}
.mat-form-field-wrapper {
  padding-bottom: 0px;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0px !important;
  padding: 0 0.75em 0 0.75em !important;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  background-color: #fff;
  // background-color: #fbf9f9;
  border-radius: 0;
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 1px 0 1px 0 !important;
}
.mat-form-field {
  font-family: Kennedys;
  font-size: 14.3px;
  font-weight: 400;
  line-height: 1.5;
}
.mat-calendar {
  font-family: Kennedys;
}
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: Kennedys;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba($default_brand, 0.3);
}
.mat-calendar-body-selected {
  background-color: $default_brand;
}
.mat-datepicker-toggle-active {
  color: $default_brand;
}
// -----------------------------------------------------------------------------
//  large circle
// -----------------------------------------------------------------------------
.iQcircle_shape {
  width: 200px;
  height: 200px;
  border-style: solid;
  border-color: Grey;
  border-width: 3px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  // word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  .fixed_content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .circleIcon_holder {
    fill: $iQ_color_dark_grey;
    svg.circleIcon_50 {
      width: 50px;
      height: 50px;
    }
  }
  .circleIcon_title {
    font-size: 19px;
  }
  .iQcircle_percentage {
    font-size: 23px;
    padding-top: 5px;
  }
}
.iQcircle_lg {
  width: 200px;
  height: 200px;
  .fixed_content {
    width: auto;
    height: calc(100% - 40px);
    margin: 20px auto;
  }
}
.iQcircle_md {
  width: 140px;
  height: 140px;
  .fixed_content {
    width: 110px;
    height: calc(100% - 40px);
    margin: 20px auto;
  }
}
.iQcircle_ex {
  width: 280px;
  height: 280px;
  .fixed_content {
    width: 212px;
    height: calc(100% - 96px);
    margin: 46px auto;
  }
  .circleIcon_title {
    font-size: 22px;
  }
}
// -----------------------------------------------------------------------------
//  iQcardRectangle
// -----------------------------------------------------------------------------
.iQcardRectangle {
  width: 200px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  .iQcardBtnHolder {
    text-align: center;
    margin-top: 10px;
    button {
      width: 60%;
      font-size: 15px;
    }
    button + button {
      margin-top: 8px;
    }
  }
  .iQcardFixed_content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    // justify-content: center;
    //  flex-direction: column;
    height: 200px;
    min-height: 100px;
    text-align: center;
  }

  span.iQcardSpan {
    margin: auto 0;
    padding: 15px;
  }
  .infoDisplay {
    border-width: 2px;
    border-style: solid;
    border-color: $iQ_color_highlight_grey;
    background: #fff;
    padding: 2px 10px;
    font-size: 14px;
  }
}

// -----------------------------------------------------------------------------
//  iQcardSquircle
// -----------------------------------------------------------------------------
.iQcardSquircle {
  width: 300px;
  border-radius: 15px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  position: relative;
  .iQcardBtnHolder {
    text-align: center;
    margin-top: 10px;
    button {
      width: 60%;
      font-size: 15px;
    }
    button + button {
      margin-top: 8px;
    }
  }
  .iQcardFixed_content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    // justify-content: center;
    //  flex-direction: column;
    // height: 200px;
    height: 145px;
    min-height: 100px;
    text-align: center;
  }
  .quest_box {
    padding-top: 45px;
  }
  span.iQcardSpan {
    margin: auto 0;
    padding: 15px;
    padding-top: 0px;
    width: 100%;
    font-size: 19px;
    line-height: 19px;
    h4 {
      font-size: 18px;
      font-weight: bold;
      margin: 0px;
      margin-bottom: 6px;
    }
  }
  .infoDisplay {
    border-width: 2px;
    border-style: solid;
    border-color: $iQ_color_highlight_grey;
    background: #fff;
    padding: 6px 10px;
    font-size: 19px;
  }
  .squircle_chart {
    position: absolute;
    top: -50px;
    left: calc(50% - 44.5px);
  }
}

// -----------------------------------------------------------------------------
//  iQpolygon shape
// -----------------------------------------------------------------------------
.iQpolygon_shape {
  .fixed_content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

// -----------------------------------------------------------------------------
//  Summary View page
// -----------------------------------------------------------------------------

.modal-content {
  .feedback_card {
    margin-top: 0 !important;
    // padding: 25px 0px 5px 0px !important;
    .modal-body {
      overflow: visible;
      p {
        margin-bottom: 0px;
      }
      button.close {
        top: -18px;
        right: 8px;
      }
    }
    .modal-footer {
      .button_holder {
        width: 100%;
      }
    }
  }
}
// -----------------------------------------------------------------------------
//  Preloarder
// -----------------------------------------------------------------------------
.settings_center_contant {
  .kcg-btn.btn-fill {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

// -----------------------------------------------------------------------------
//  settings page
// -----------------------------------------------------------------------------
.settings_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 177px);
}

.settings_center_contant {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: 1.75rem auto;
  padding: 15px 0px;
  max-height: 700px;
}
.tab_wrapper {
  height: 100%;
  width: 100%;
  .tab_menu_section {
    width: 230px;
    float: left;
    height: 100%;
    border: 1px solid #ccc;
    border-right: none;
    background: #fff;
  }
  .tab_content_items {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #ccc;
    background: #fff;
    margin-left: 230px;
    &_body {
      flex: 1 0 auto;
      width: 100%;
      min-height: auto;
      height: calc(100% - 130px);
    }
    &_footer {
      padding: 3px;
      border-top: 1px solid #ccc;
    }
    &_scroll {
      overflow-y: auto;
      height: calc(100% - 10px);
      padding: 15px;
      text-align: center;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      // -webkit-box-pack: center;
      // -ms-flex-pack: center;
      // -webkit-justify-content: center;
      // justify-content: center;
      // -webkit-box-align: center;
      // -ms-flex-align: center;
      // -webkit-align-items: center;
      // align-items: center;
    }
    .form-group {
      width: 100%;
    }
  }
}

.tabMenuUl {
  margin: 0px;
  padding: 0px;
  li {
    padding: 20px 15px 15px 20px;
    display: block;
    list-style: none;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: 5px;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      background: transparent;
      transition: all 0.3s ease;
    }
    &:hover {
      background: #f7f7f7;
    }
    &.active {
      background: #e3e3e3;

      &:after {
        background: $default_brand;
      }
    }
    label {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;
      padding: 0px;
    }
  }
}

// -----------------------------------------------------------------------------
//  Preloarder
// -----------------------------------------------------------------------------

.loader {
  color: #fff;
  font-size: 10px;
  margin: 50px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }

  12.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0em,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }

  75% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0 0 0.2em,
      -2em -2em 0 0;
  }

  87.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0 0 0,
      -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }

  12.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0em,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }

  75% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0 0 0.2em,
      -2em -2em 0 0;
  }

  87.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0 0 0,
      -2em -2em 0 0.2em;
  }
}

.page-loader-title {
  color: #fff !important;
  font-size: 17px;
}

.page-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999999999999999;
  position: fixed;
  display: table;
}

.page-loader {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
// -----------------------------------------------------------------------------
//  circular percentage chart
// -----------------------------------------------------------------------------
.circularAni {
  animation: circularProgress 1s ease-out forwards;
}

/// Collection for iterative functions

@each $colorName, $colorValue in $iQbrandColors {
  .iQring-#{$colorName} {
    border-color: #{$colorValue};
  }
  .iQring-#{$colorName}.iQcircle_ex {
    .circleIcon_title {
      color: #{$colorValue};
    }
  }
  .iQring_chart-#{$colorName} {
    .ringStroke {
      stroke: #{$colorValue};
    }
  }
  .iQpolygon-#{$colorName} {
    .iQpolygon_stroke {
      stroke: #{$colorValue};
    }
  }
  .fillCard-#{$colorName} {
    background: #{$colorValue};
  }
  .strokeCard-#{$colorName} {
    border-color: #{$colorValue};
  }
  .iQ_chip-#{$colorName} {
    border-color: #{$colorValue};
  }
  .brand_icon-#{$colorName} {
    fill: #{$colorValue};
  }
  .iQ_legend_color-#{$colorName} {
    background: #{$colorValue};
    border-color: #{$colorValue};
  }
}

// -----------------------------------------------------------------------------
//  PDF
// -----------------------------------------------------------------------------
// .iQcard_field_col{
//   max-width: auto !important;
// }
.pageSpacePdf {
  .iQcard_field-text {
    font-size: 20px;
  }
  .iQ_chip {
    font-size: 16px !important;
  }
  .brand_icon_28 {
    width: 34px;
    height: 34px;
  }
  .brand_icon_12 {
    width: 15px;
    height: 15px;
  }
  .time_date_info {
    font-size: 18px;
  }
  .pdf_time_col {
    padding-left: 0px;
  }
  table.summaryTbl {
    tbody {
      tr {
        border-bottom: 1px solid #dededf;
        td {
          vertical-align: middle !important;
        }
      }
      // tr:nth-child(odd) {
      //   background: #dededf;
      // }

      // tr:nth-child(even) {
      //   background: rgba(0, 0, 0, 0.075);
      // }
    }
  }
  .iQcard_field_col {
    flex: 0 0 33.3%;
    .iQcard_field-text.text-truncate {
      overflow: inherit;
      text-overflow: inherit;
      white-space: pre-line;
    }
    .iQcard_field {
      align-items: self-start;
    }
  }
}
// -----------------------------------------------------------------------------
//  Status
// -----------------------------------------------------------------------------
.status_warning {
  text-align: center;
  background: #ffedc6;
  border: 1px solid #f1cb77;
  padding: 5px 15px;
  margin-bottom: -1px;
  z-index: 1;
  position: relative;
  font-weight: bold;
  .warning_closeBtn {
    padding: 1px 8px;
    padding-right: 5px;
    line-height: 10px;
    font-weight: normal;
    color: #5e5e5e;
    &:hover {
      color: $default_brand;
    }
  }
}
// -----------------------------------------------------------------------------
//  uniclaims
// -----------------------------------------------------------------------------
body.headless {
  .containerSpace {
    padding-top: 0px !important;
  }
  .page_space {
    padding-bottom: 15px;
  }
  .b_space_card {
    padding-bottom: 1px;
  }
  .smart_wizard {
    height: calc(100% - 60px);
  }
  .smart_wizard_space {
    padding-bottom: 15px;
  }
  .claim_canvas_holder {
    height: calc(100vh - 215px);
  }
}

// -----------------------------------------------------------------------------
//  feedback
// -----------------------------------------------------------------------------

.feedback_modal {
  width: 100vw;
  max-width: 1140px !important;
  height: 100%;
  max-height: 95%;
  margin: 0 auto !important;
  .feedback_card {
    height: 100%;
  }
  .modal-content {
    height: 100%;
    max-height: 95%;
    border: 0;
    border-radius: 0;
    margin-left: 15px;
    margin-right: 15px;
    .modal-header {
      height: auto;
      //padding-right: 50px;
      background: none;
      border: none;
      text-align: center;
      h5.modal-title {
        color: #fff;
      }
      .close {
        color: #000;
      }

      .feedback_quest {
        width: 100%;
        p {
          font-size: 16px;
          font-weight: bold;
          margin: 0px;
        }
      }
    }
    .formFlex {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 15px;
      .button_holder {
        text-align: center;
      }
      textarea.form-control {
        height: 100%;
      }
      .feedback_quest {
        width: 100%;
        text-align: center;
        p {
          font-size: 16px;
          font-weight: bold;
          margin: 0px;
        }
      }
    }
    .modal-footer {
    }
  }
}
.recommendation_modal {
  .modal-content {
    .modal-header {
      height: auto;
      padding-right: 50px;
      background: none;
      border: none;
      text-align: center;
      .close {
        color: #000;
      }

      .feedback_quest {
        width: 100%;
        p {
          font-size: 16px;
          font-weight: bold;
          margin: 0px;
        }
      }
    }
    .modal-body {
      padding-top: 0px;
      .button_holder {
        text-align: center;
      }
      .feedback_quest {
        width: 100%;
        text-align: center;
        p {
          font-size: 16px;
          font-weight: bold;
          margin: 0px;
        }
      }
    }
  }
}
.modal-dialog-centered.modal-lg.feedback_modal {
  max-width: 1050px !important;
}
ngb-modal-window .feedback_modal.modal-dialog .modal-content .modal-header {
  //background: none;
  // .close {
  //   color: #000;
  // }
}

ngb-modal-window .recommendation_modal.modal-dialog .modal-content .modal-header {
  background: none;
  .close {
    color: #000;
  }
}
.k-footer {
  z-index: 99 !important;
}

// remove on bootstrap 5 ________▼
.previewModal {
  .modal-content {
    .modal-header {
      .btn-close {
        background: transparent;
        border: none;
      }
    }
  }
}

.btn-close::before {
  display: inline-block;
  content: "+";
  font-size: 27px;
  line-height: 26px;
  transform: rotate(45deg);
  color: #fff;
}
// remove on bootstrap 5 ________▲

// -----------------------------------------------------------------------------
//  Media
// -----------------------------------------------------------------------------
@media (max-width: 1280px) {
  .page_title {
    padding-top: 6px;
    padding-bottom: 6px;
    h3 {
      font-size: 16px;
      line-height: 28px;
    }
  }
  k-header .k-header-title {
    max-width: 210px;
    margin: 14px 0;
  }
  .k-footer {
    height: 46px !important;
    .k-logo {
      svg {
        margin-top: 6px !important;
      }
    }
  }
  .buttom_svg_icon.export_svg_mob {
    .brand_icon {
      width: 28px;
      height: 28px;
    }
  }
  .iqwizard_container {
    .iqCategory_wrap .iQ_w_arrow-down {
      opacity: 0.6;
    }
    .iQswiper {
      &_header {
        padding: 6px 15px;
      }
    }
    .preview_card {
      .preview_scroll {
        .preview_col {
          flex: 0 0 60px;
          height: 60px;
          padding: 3px;
          img {
            width: 45px !important;
          }
        }
      }
    }
  }

  .feedback_card {
    .kcg-btn {
      padding: 0.7rem 3.75rem 0.7rem 1.25rem !important;
    }
  }
  .btnExport {
    padding: 4px 52px 4px 10px;
    font-size: 0.875rem;

    i {
      top: 32%;
      font-size: 12px;
    }
    .loadingIcon {
      top: 10px;
    }
  }
}
@media (max-width: 1140px) {
  .modal-dialog-centered.feedback_modal {
    max-width: 90% !important;
  }
}
@media (max-width: 992px) {
  .nav {
    li.nav-item {
      width: auto;
    }
  }
  .settings_center {
    height: calc(100vh - 240px);
  }
  .settings_center_contant {
    margin: 0 auto;
    padding: 15px 0px;
    max-height: inherit;
  }

  .tab_wrapper {
    .tab_menu_section {
      width: 100%;
      height: auto;
      border: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: none;
      text-align: center;
    }
    .tab_content_items {
      margin-left: 0px;
    }
  }
  .modal {
    .assignUseCase_modal.modal-dialog-centered.modal-lg {
      max-width: 500px !important;
      margin: 1.75rem auto;
    }
  }
}

@media (max-width: 768px) {
  // .dash_filterForm {
  //   margin-bottom: 15px;
  // }
  .claimBtnHolder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .claimBtnHolder_col {
    position: relative;
    // border-left: 1px solid lightgray;
    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: calc(100% - 15px);
      top: 0;
      left: 0;
      z-index: 1;
      background: lightgray;
    }
  }
  .liabilityIndicator_info {
    border-left-width: none !important;
    border-top-width: 15px;
    border-top-color: #e7e8e9;
    border-top-style: solid;
  }
}

@media (max-width: 576px) {
  .modal-dialog-centered.feedback_modal {
    max-width: 100% !important;
    .modal-content {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .settings_center {
    height: auto;
    padding-bottom: 60px;
  }
}

@media (max-width: 400px) {
  .dash_filterForm {
    margin-bottom: 15px;
  }
}
