// -----------------------------------------------------------------------------
// This file contains styles that are specific to the variables scss.
// -----------------------------------------------------------------------------
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}
// keyframes mixin
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
@include keyframes(circularProgress) {
  0% {
    stroke-dasharray: 0 100;
  }
}


@include keyframes(analysis_needle_animation)  {
  0% {
    transform: rotate(-90deg);
}

50% {
    transform: rotate(90deg);
}
}

// colours

$default_brand: #00ab8e; 
$iQ_color_red: #ff0000;
$iQ_color_green: #009900;
$iQ_color_amber: #ffbf00;
$iQ_color_bg_grey: #e6e6e6;
$iQ_color_mid_grey: #d3d3d3;
$iQ_color_dark_grey: #4d4d4d;
$iQ_color_highlight_grey: #888b90;

/// Collection for iterative functions
$iQbrandColors: (
  "iQred": $iQ_color_red,
  "iQgreen": $iQ_color_green,
  "iQamber": $iQ_color_amber,
  "iQbg_grey": $iQ_color_bg_grey,
  "iQmid_grey": $iQ_color_mid_grey,
  "iQdark_grey": $iQ_color_dark_grey,
  "iQgrey": $iQ_color_highlight_grey,
  "iQhighlight_grey": $iQ_color_highlight_grey,
  "transparent": transparent,
); 
 